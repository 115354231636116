import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataPagesList: [],
  dataPagesDetail: [],
  loading: false,
  error: undefined,
};

const caseStudySlice = createSlice({
  name: "caseStudy",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchAllPagesList(state, action) {
      state.dataPagesList = action.payload;
      state.dataPagesDetail = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesDetail(state, action) {
      state.dataPagesDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllCaseStudyCategoriesList(state, action) {
      state.error = undefined;
      state.loading = false;
    },
  },
});

export const caseStudyActions = caseStudySlice.actions;

export default caseStudySlice.reducer;
