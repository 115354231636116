import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataPagesList: [],
  dataPagesDetail: [],
  loading: false,
  error: undefined,
};

const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    fetchAllPagesList(state, action) {
      state.dataPagesList = action.payload;
      state.dataPagesDetail = [];
      state.error = undefined;
      state.loading = false;
    },
    fetchAllPagesDetail(state, action) {
      state.dataPagesDetail = action.payload;
      state.error = undefined;
      state.loading = false;
    },
    fetchAllTestimonialsCategoriesList(state, action) {
      state.error = undefined;
      state.loading = false;
    },
  },
});

export const testimonialsActions = testimonialsSlice.actions;

export default testimonialsSlice.reducer;
