import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

const LoadingIndicator = (props) => {
    return (
        <div className={`loader-main-content ${!!props.loading}`}>
            <ScaleLoader color="#176B87" loading={!!props.loading} size={150} />
        </div>
    );
};

export default LoadingIndicator;
