import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";

const initialState = {
  setMenuItemsList: [],
  loggedUser: {},
  validToken: false,
  loading: false,
  error: undefined,
  token: null,
};

const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
    actionStart(state) {
      state.loading = true;
      state.error = undefined;
    },
    setMenuItemsList(state, action) {
      state.setMenuItemsList = action.payload;
      state.loading = false;
    },
    setCurrentUser(state, action) {
      state.validToken = !isEmpty(action.payload);
      state.loggedUser = action.payload;
      state.error = undefined;
      state.loading = false;
      state.token = action.payload.validToken;
    },
  },
});

export const securityActions = securitySlice.actions;

export default securitySlice.reducer;
