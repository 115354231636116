import api from "../api";

const apiService = {
  get(url) {
    return api.get(url, {
      headers: getSimpleHeaders(),
    });
  },
  post(url, body) {
    return api.post(url, body, {
      headers: getSimpleHeaders(),
    });
  },
  delete(url) {
    return api.delete(url, {
      headers: getSimpleHeaders(),
    });
  },

  getFromFile(url) {
    return api.get(url, {
      responseType: "blob",
      headers: getSimpleHeaders(),
    });
  },
};

function getSimpleHeaders() {
  const phpToken = localStorage.getItem("phpToken");
  return {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `${phpToken}`,
  };
}

export default apiService;
